import React from "react";
import "./PrivacyNotice.css";

import ReturnToTop from "../ReturnToTop/ReturnToTop";
import { HashLink } from "react-router-hash-link";

function PrivacyNotice() {

  return (
    <div className="content" id="privacy-content">
      {/* Heading */}
      <div className="content_heading">
        <h2>Privacy Notice</h2>
        <p>Last updated July 09, 2023</p>
      </div>
      {/* intro */}
      <div className="content_intro">
        <p>
          This privacy notice for Questgrid, llc ("
          <span className="slight-bold">Company</span>," "
          <span className="slight-bold">we</span>," "
          <span className="slight-bold">us</span>," or "
          <span className="slight-bold">our</span>"), describes how and why we
          might collect, store, use, and/or share ("
          <span className="slight-bold">process</span>") your information when
          you use our services ("
          <span className="slight-bold">Services</span>"), such as when you:
        </p>
        <ul>
          <li>
            Download and use our application(s), such as our mobile application
            — Questgrid, or any other application of ours that links to this
            privacy notice
          </li>
          <li>
            Engage with us in other related ways ― including any sales,
            marketing, or events
          </li>
        </ul>
        <p>
          <span className="slight-bold">Questions or concerns?</span> Reading
          this privacy notice will help you understand your privacy rights and
          choices. If you do not agree with our policies and practices, please
          do not use our Services. If you still have any questions or concerns,
          please contact us at support@questgrid.com.
        </p>
      </div>
      {/* summary of key points */}
      <div className="content_key-points">
        <h2>Summary Of Key Points</h2>
        <p className="intro">
          <span className="slight-bold">
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </span>
        </p>
        <p>
          <span className="slight-bold">
            What personal information do we process?
          </span>{" "}
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with Questgrid, llc and the
          Services, the choices you make, and the products and features you use.
          Click{" "}
          <HashLink className="content-link" to={`/privacy#info-collect`}>
            here
          </HashLink>{" "}
          to learn more.
        </p>
        <p>
          <span className="slight-bold">
            Do we process any sensitive personal information?
          </span>{" "}
          We do not process sensitive personal information.
        </p>
        <p>
          <span className="slight-bold">
            Do you receive any information from third parties?
          </span>{" "}
          We do not receive any information from third parties.
        </p>
        <p>
          <span className="slight-bold">
            How do you process my information?
          </span>{" "}
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. Click{" "}
          <HashLink className="content-link" to={`/privacy#process-info`}>
            here
          </HashLink>{" "}
          to learn more.
        </p>
        <p>
          <span className="slight-bold">What are your rights?</span> Depending
          on where you are located geographically, the applicable privacy law
          may mean you have certain rights regarding your personal information.
          Click{" "}
          <HashLink className="content-link" to={`/privacy#privacy-rights`}>
            here
          </HashLink>{" "}
          to learn more.
        </p>
        <p>
          <span className="slight-bold">How do I exercise my rights?</span> The
          easiest way to exercise your rights is by filling out our data subject
          request form available here:{" "}
          <a className="content-link" href="https://questgrid.com/">
            https://questgrid.com/
          </a>
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
        <p>
          Want to learn more about what Questgrid, llc does with any information
          we collect? Click{" "}
          <HashLink className="content-link" to={`/privacy#make-updates`}>
            here
          </HashLink>{" "}
          to review the notice in full.
        </p>
      </div>
      {/* ----------------- */}
      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* Table Of Contents */}
      {/* 1. What information do we collect? */}
      <div className="content_info-collect each-section" id="info-collect">
        <h2 className="heading-h2">What Information Do We Collect?</h2>
        <h3 className="heading-h3">Personal information you disclose to us</h3>
        <p className="in-short">
          In Short: We collect personal information that you provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          <span className="slight-bold">
            Personal Information Provided by You.
          </span>{" "}
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
        </p>
        <ul>
          <li>names</li>
          <li>phone numbers</li>
          <li>email addresses</li>
          <li>job titles</li>
          <li>usernames</li>
          <li>passwords</li>
          <li>contact or authentication data</li>
        </ul>
        <p>

          If you use our
          application(s), we also may collect the following information if you
          choose to provide us with access or permission:
            <br />
            <br />
          <span className="slight-bold">Sensitive Information.</span>
          <ul>
            <li>
              <span className="slight-bold">Geolocation Information.</span> We may
              request access or permission to track location-based information
              from your mobile device, either continuously or while you are using
              our mobile application(s), to provide certain location-based
              services. If you wish to change our access or permissions, you may
              do so in your device's settings.
            </li>
          </ul>
        </p>
        <p>
          <span className="slight-bold">Application Data.</span>
        </p>
        <ul>
          <li>
            <span className="slight-bold">Mobile Device Data.</span> We
            automatically collect device information (such as your mobile device
            ID, model, and manufacturer), operating system, version information
            and system configuration information, device and application
            identification numbers, browser type and version, hardware model
            Internet service provider and/or mobile carrier, and Internet
            Protocol (IP) address (or proxy server). If you are using our
            application(s), we may also collect information about the phone
            network associated with your mobile device, your mobile device’s
            operating system or platform, the type of mobile device you use,
            your mobile device’s unique device ID, and information about the
            features of our application(s) you accessed.
          </li>
          <li>
            <span className="slight-bold">Push Notifications.</span> We may
            request to send you push notifications regarding your account or
            certain features of the application(s). If you wish to opt out from
            receiving these types of communications, you may turn them off in
            your device's settings.
          </li>
          <li>
            <span className="slight-bold">Read External Storage.</span> We may request access to read files and media stored on your device. This permission is used to enhance user experience by allowing the application to read files, such as images or documents, that you choose to upload or interact with in the app.
          </li>

          <li>
            <span className="slight-bold">Display Over Other Apps.</span> This permission allows the application to display content over other applications. It is primarily used for features  for quick access overlays that enhance usability.
          </li>

          <li>
            <span className="slight-bold">Vibration Control</span> This permission enables the application to control the vibration feature of your device. It is used for providing haptic feedback, notifications, or alerts to enhance user interaction.
          </li>

          <li>
            <span className="slight-bold">Write External Storage</span> This permission allows the application to save files and data to your device’s storage. It ensures that user data, such as downloaded content or application-specific files, is securely stored and accessible for future use.

          </li>



        </ul>
        <p>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h3 className="heading-h3">Information automatically collected</h3>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> Some information —
            such as your Internet Protocol (IP) address and/or browser and
            device characteristics — is collected automatically when you visit
            our Services.
          </i>
        </p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <p>The information we collect includes:</p>
        <ul>
          <li>
            <span className="slight-bold">Log and Usage Data.</span> Log and
            usage data is service-related, diagnostic, usage, and performance
            information our servers automatically collect when you access or use
            our Services and which we record in log files. Depending on how you
            interact with us, this log data may include your IP address, device
            information, browser type, and settings and information about your
            activity in the Services (such as the date/time stamps associated
            with your usage, pages and files viewed, searches, and other actions
            you take such as which features you use), device event information
            (such as system activity, error reports (sometimes called "crash
            dumps"), and hardware settings).
          </li>
          <li>
            <span className="slight-bold">Location Data.</span> We collect
            location data such as information about your device's location,
            which can be either precise or imprecise. How much information we
            collect depends on the type and settings of the device you use to
            access the Services. For example, we may use GPS and other
            technologies to collect geolocation data that tells us your current
            location (based on your IP address). You can opt out of allowing us
            to collect this information either by refusing access to the
            information or by disabling your Location setting on your device.
            However, if you choose to opt out, you may not be able to use
            certain aspects of the Services.
          </li>
        </ul>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 2. HOW DO WE PROCESS YOUR INFORMATION? */}
      <div className="content_process-info each-section" id="process-info">
        <h2 className="heading-h2">How Do We Process Your Information?</h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent.
          </i>
        </p>
        <p>
          <span className="slight-bold">
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </span>
        </p>
        <ul>
          <li>
            <span className="slight-bold">
              To facilitate account creation and authentication and otherwise
              manage user accounts.
            </span>{" "}
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li>
            <span className="slight-bold">
              To deliver and facilitate delivery of services to the user.
            </span>{" "}
            To deliver and facilitate delivery of services to the user.
          </li>
          <li>
            <span className="slight-bold">
              To respond to user inquiries/offer support to users.
            </span>{" "}
            We may process your information to respond to your inquiries and
            solve any potential issues you might have with the requested
            service.
          </li>
          <li>
            <span className="slight-bold">
              To send administrative information to you.
            </span>{" "}
            We may process your information to send you details about our
            products and services, changes to our terms and policies, and other
            similar information.
          </li>
          <li>
            <span className="slight-bold">
              To fulfill and manage your orders.
            </span>{" "}
            We may process your information to fulfill and manage your orders,
            payments, returns, and exchanges made through the Services.
          </li>
          <li>
            <span className="slight-bold">
              To enable user-to-user communications.
            </span>{" "}
            We may process your information if you choose to use any of our
            offerings that allow for communication with another user.
          </li>
          <li>
            <span className="slight-bold">To request feedback.</span> We may
            process your information when necessary to request feedback and to
            contact you about your use of our Services.
          </li>
          <li>
            <span className="slight-bold">
              To send you marketing and promotional communications.
            </span>{" "}
            We may process the personal information you send to us for our
            marketing purposes, if this is in accordance with your marketing
            preferences. You can opt out of our marketing emails at any time.
            For more information, see "
            <HashLink className="content-link" to={`/privacy#privacy-rights`}>
              WHAT ARE YOUR PRIVACY RIGHTS?
            </HashLink>
            " below).
          </li>
          <li>
            <span className="slight-bold">To post testimonials.</span> We post
            testimonials on our Services that may contain personal information.
          </li>
          <li>
            <span className="slight-bold">To protect our Services.</span> We may
            process your information as part of our efforts to keep our Services
            safe and secure, including fraud monitoring and prevention.
          </li>
          <li>
            <span className="slight-bold">
              To evaluate and improve our Services, products, marketing, and
              your experience.
            </span>{" "}
            We may process your information when we believe it is necessary to
            identify usage trends, determine the effectiveness of our
            promotional campaigns, and to evaluate and improve our Services,
            products, marketing, and your experience.
          </li>
          <li>
            <span className="slight-bold">To identify usage trends.</span> We
            may process information about how you use our Services to better
            understand how they are being used so we can improve them.
          </li>
          <li>
            <span className="slight-bold">
              To comply with our legal obligations.
            </span>{" "}
            We may process your information to comply with our legal
            obligations, respond to legal requests, and exercise, establish, or
            defend our legal rights.
          </li>
        </ul>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? */}
      <div className="content_share-info each-section" id="share-info">
        <h2 className="heading-h2">
          When and with whom do we share your personal information?
        </h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We may share
            information in specific situations described in this section and/or
            with the following third parties.
          </i>
        </p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            <span className="slight-bold">Business Transfers.</span> We may
            share or transfer your information in connection with, or during
            negotiations of, any merger, sale of company assets, financing, or
            acquisition of all or a portion of our business to another company.
          </li>
          <li>
            <span className="slight-bold">
              When we use Google Maps Platform APIs.
            </span>{" "}
            We may share your information with certain Google Maps Platform APIs
            (e.g., Google Maps API, Places API). To find out more about Google’s
            Privacy Policy, please refer to this{" "}
            <a
              className="content-link"
              href="https://policies.google.com/privacy"
            >
              link
            </a>
            . We use certain Google Maps Platform APIs to retrieve certain
            information when you make location-specific requests. This includes:
            User name and avatar customization. ; and other similar information.
            A full list of what we use information for can be found in this
            section and in the previous section titled “
            <HashLink className="content-link" to={`/privacy#process-info`}>
              HOW DO WE PROCESS YOUR INFORMATION?
            </HashLink>
            ”. We obtain and store on your device ('cache') your location. You
            may revoke your consent anytime by contacting us at the contact
            details provided at the end of this document. The Google Maps
            Platform APIs that we use store and access cookies and other
            information on your devices. If you are a user currently in the
            European Economic Area (EU countries, Iceland, Liechtenstein and
            Norway) or the United Kingdom, please take a look at our Cookie
            Notice.
          </li>
          <li>
            <span className="slight-bold">Business Partners.</span> We may share
            your information with our business partners to offer you certain
            products, services, or promotions.
          </li>
        </ul>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? */}
      <div className="content_use-tech each-section" id="use-tech">
        <h2 className="heading-h2">
          Do we use cookies and other tracking technologies?
        </h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We may use cookies
            and other tracking technologies to collect and store your
            information.
          </i>
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 5. HOW LONG DO WE KEEP YOUR INFORMATION? */}
      <div className="content_keep-info each-section" id="keep-info">
        <h2 className="heading-h2">How long do we keep your information?</h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> We keep your
            information for as long as necessary to fulfill the purposes
            outlined in this privacy notice unless otherwise required by law.
          </i>
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 6. HOW DO WE KEEP YOUR INFORMATION SAFE? */}
      <div className="content_info-safe each-section" id="info-safe">
        <h2 className="heading-h2">How do we keep your information safe?</h2>
        <p>
          <i>
            <span>In Short:</span> We aim to protect your personal information
            through a system of organizational and technical security measures.
          </i>
        </p>
        <p>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 7. WHAT ARE YOUR PRIVACY RIGHTS? */}
      <div className="content_privacy-rights each-section" id="privacy-rights">
        <h2 className="heading-h2">What are your privacy rights?</h2>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your local data protection supervisory authority. You can
          find their contact details here:
          <br />
          <a
            className="content-link"
            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
          >
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          .
        </p>
        <p>
          If you are located in Switzerland, the contact details for the data
          protection authorities are available here:
          <br />
          <a
            className="content-link"
            href="https://www.edoeb.admin.ch/edoeb/en/home.html"
          >
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </a>
          .
        </p>
        <p>
          <span className="slight-bold">
            <u>Withdrawing your consent:</u>
          </span>{" "}
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section "
          <HashLink className="content-link" to={`/privacy#contact-us`}>
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </HashLink>
          " below.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal, nor when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <h3 className="heading-h3">Account Information</h3>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>
          <span className="slight-bold">
            <u>Cookies and similar technologies:</u>
          </span>{" "}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. To opt
          out of interest-based advertising by advertisers on our Services visit{" "}
          <a href="http://www.aboutads.info/choices/" className="content-link">
            http://www.aboutads.info/choices/
          </a>
          .
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at support@questgrid.com.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 8. CONTROLS FOR DO-NOT-TRACK FEATURES */}
      <div className="content_do-not-track each-section" id="do-not-track">
        <h2 className="heading-h2">Controls for do-not-track features</h2>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? //Table// */}
      <div className="content_cali-p-rights each-section" id="cali-p-rights">
        <h2 className="heading-h2">
          Do california residents have specific privacy rights?
        </h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> Yes, if you are a
            resident of California, you are granted specific rights regarding
            access to your personal information.
          </i>
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g., backups, etc.).
        </p>

        <h3 className="heading-h3">CCPA Privacy Notice</h3>
        <p>The California Code of Regulations defines a "resident" as:</p>
        <ul>
          <li>
            every individual who is in the State of California for other than a
            temporary or transitory purpose and
          </li>
          <li>
            every individual who is domiciled in the State of California who is
            outside the State of California for a temporary or transitory
            purpose
          </li>
        </ul>
        <p>All other individuals are defined as "non-residents."</p>
        <p>
          If this definition of "resident" applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </p>

        <h3 className="heading-h3">
          What categories of personal information do we collect?
        </h3>
        <p>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>
        {/* Table */}
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Category</th>
              <th scope="col">Examples</th>
              <th scope="col">Collected</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">A</th>
              <td>Identifiers</td>
              <td>
                Contact details, such as real name, alias, postal address,
                telephone or mobile contact number, unique personal identifier,
                online identifier, Internet Protocol address, email address, and
                account name
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <th scope="row">B</th>
              <td>
                Personal information categories listed in the California
                Customer Records statute
              </td>
              <td>
                Name, contact information, education, employment, employment
                history, and financial information
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <th scope="row">C</th>
              <td>
                Protected classification characteristics under California or
                federal law
              </td>
              <td>Gender and date of birth</td>
              <td>NO</td>
            </tr>
            <tr>
              <th scope="row">D</th>
              <td>Commercial information</td>
              <td>
                Transaction information, purchase history, financial details,
                and payment information
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <th scope="row">E</th>
              <td>Biometric information</td>
              <td>Fingerprints and voiceprints</td>
              <td>NO</td>
            </tr>
            <tr>
              <th scope="row">F</th>
              <td>Internet or other similar network activity</td>
              <td>
                Browsing history, search history, online behavior, interest
                data, and interactions with our and other websites,
                applications, systems, and advertisements
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <th scope="row">G</th>
              <td>Geolocation data</td>
              <td>Device location</td>
              <td>NO</td>
            </tr>
            <tr>
              <th scope="row">H</th>
              <td>
                Audio, electronic, visual, thermal, olfactory, or similar
                information
              </td>
              <td>
                Images and audio, video or call recordings created in connection
                with our business activities
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <th scope="row">I</th>
              <td>Professional or employment-related information</td>
              <td>
                Business contact details in order to provide you our services at
                a business level or job title, work history, and professional
                qualifications if you apply for a job with us
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <th scope="row">J</th>
              <td>Education Information</td>
              <td>Student records and directory information</td>
              <td>NO</td>
            </tr>
            <tr>
              <th scope="row">K</th>
              <td>Inferences drawn from other personal information</td>
              <td>
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example,
                an individual’s preferences and characteristics
              </td>
              <td>NO</td>
            </tr>
          </tbody>
        </table>

        <p>
          We may also collect other personal information outside of these
          categories instances where you interact with us in person, online, or
          by phone or mail in the context of:
        </p>
        <ul>
          <li>Receiving help through our customer support channels;</li>
          <li>Participation in customer surveys or contests; and</li>
          <li>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </li>
        </ul>
        <h3 className="heading-h3">
          How do we use and share your personal information?
        </h3>
        <p>
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </p>
        <p>
          You may contact us by email at support@questgrid.com, or by referring
          to the contact details at the bottom of this document.
        </p>
        <p>
          If you are using an authorized agent to exercise your right to opt out
          we may deny a request if the authorized agent does not submit proof
          that they have been validly authorized to act on your behalf.
        </p>
        <h3 className="heading-h3">
          Will your information be shared with anyone else?
        </h3>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf.
        </p>
        <p>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal information.
        </p>
        <p>
          Questgrid, llc has not disclosed or sold any personal information to
          third parties for a business or commercial purpose in the preceding
          twelve (12) months. Questgrid, llc will not sell personal information
          in the future belonging to website visitors, users, and other
          consumers.
        </p>
        <h3 className="heading-h3">
          Your rights with respect to your personal data
        </h3>
        <p>
          <u>Right to request deletion of the data — Request to delete</u>
        </p>
        <p>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation, or any processing that
          may be required to protect against illegal activities.
        </p>
        <p>
          <u>Right to be informed — Request to know</u>
        </p>
        <p>Depending on the circumstances, you have a right to know:</p>
        <ul>
          <li>whether we collect and use your personal information;</li>
          <li>the categories of personal information that we collect;</li>
          <li>
            the purposes for which the collected personal information is used;
          </li>
          <li>whether we sell your personal information to third parties;</li>
          <li>
            the categories of personal information that we sold or disclosed for
            a business purpose;
          </li>
          <li>
            the categories of third parties to whom the personal information was
            sold or disclosed for a business purpose; and
          </li>
          <li>
            the business or commercial purpose for collecting or selling
            personal information.
          </li>
        </ul>
        <p>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </p>
        <p>
          <u>
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights
          </u>
        </p>
        <p>
          We will not discriminate against you if you exercise your privacy
          rights.
        </p>
        <p>
          <u>Verification process</u>
        </p>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g., phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </p>

        <p>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. However, if we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </p>
        <p>
          <u>Other privacy rights</u>
        </p>
        <ul>
          <li>
            You may object to the processing of your personal information.
          </li>
          <li>
            You may request correction of your personal data if it is incorrect
            or no longer relevant, or ask to restrict the processing of the
            information.
          </li>
          <li>
            You can designate an authorized agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorized agent
            that does not submit proof that they have been validly authorized to
            act on your behalf in accordance with the CCPA.
          </li>
          <li>
            You may request to opt out from future selling of your personal
            information to third parties. Upon receiving an opt-out request, we
            will act upon the request as soon as feasibly possible, but no later
            than fifteen (15) days from the date of the request submission.
          </li>
        </ul>
        <p>
          To exercise these rights, you can contact us by email at
          support@questgrid.com, or by referring to the contact details at the
          bottom of this document. If you have a complaint about how we handle
          your data, we would like to hear from you.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 10. DO WE MAKE UPDATES TO THIS NOTICE? */}
      <div className="content_make-updates each-section" id="make-updates">
        <h2 className="heading-h2">Do we make updates to this notice?</h2>
        <p>
          <i>
            <span className="slight-bold">In Short:</span> Yes, we will update
            this notice as necessary to stay compliant with relevant laws.
          </i>
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? */}
      <div className="content_contact-us each-section" id="contact-us">
        <h2 className="heading-h2">
          How can you contact us about this notice?
        </h2>
        <p>
          If you have questions or comments about this notice, you may contact
          our Data Protection Officer (DPO), James Gary, by email at
          support@questgrid.com, by phone at 3103409520, or by post to:
        </p>
        <p>
          James Gary
          <br />
          196 Main Ave.
          <br />
          Clifton, NJ 07140
          <br />
          United States
        </p>
        <p>
          If you have any further questions or comments, you may also contact us
          by post at the following corporate address:
        </p>
        <p>
          Questgrid, llc
          <br />
          8 The Green, Suite A<br />
          Dover, DE 19901
          <br />
          United States
          <br />
          Phone: 3022880670
          <br />
        </p>
      </div>

      <ReturnToTop hashUrl={"privacy-content"} category={"Privacy Notice"} />
      {/* 12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? */}
      <div className="content_data-you each-section" id="data-you">
        <h2 className="heading-h2">
          How can you review, update, or delete the data we collect from you?
        </h2>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          visit:{" "}
          <a className="content-link" href="https://questgrid.com/">
            https://questgrid.com/
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default PrivacyNotice;
