import * as React from "react"
import appleStore from "../../Images/AppStoreButton.png";

const AppStoreButton = (props) => (
  <a href="https://apps.apple.com/us/app/questgrid/id1584633347">

    <img
      src={appleStore}
      alt="apple store link"
      width={props.width || 'auto'}
      height={props.height}
      {...props}
    />
  </a>
);


export default AppStoreButton
