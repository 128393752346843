import React from 'react';
import { Link } from "react-router-dom";
import GooglePlayButton from "./GooglePlayButton";
import "./Footer.css";
import AppStoreButton from "./AppStoreButton";
import applemusic from "../../Images/applemusic.png";

export default function Footer() {
    return (
        <div className="links-div">
            <div className='main-links'>
                <AppStoreButton width="140" height="43" />
                <GooglePlayButton width="140" height="43" />
                
            </div>

            <p>Est. 2020</p>
            <Link className="privacy-terms-link" to="/privacy">
                Privacy & Terms of Service
            </Link>
        </div>
    );
}
