import * as React from "react";
import googlePlay from "../../Images/googlePlay.png";

const GooglePlayButton = (props) => (
  // google play button
  <a href="https://play.google.com/store/apps/details?id=com.questgrid.app">
    <img
      src={googlePlay}
      alt="Google Play"
      width={props.width || 'auto'}
      height={props.height}
      {...props}
    />
  </a>
);

export default GooglePlayButton;